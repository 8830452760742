@tailwind base;
@tailwind components;
@tailwind utilities;

.theme_toggler {
  position: absolute;
  right: 3%;
  bottom: 2%;
  background: linear-gradient(135deg, #868cff 0%, #856da7 100%);
  border: 1px solid #6a53ff;
  border-radius: 30px;
}

#item-1:hover .icon {
  color: #856da7;
}

#item-1.activeNav .icon {
  color: #856da7;
}

tbody:before {
  content: "@";
  display: block;
  line-height: 8px;
  text-indent: -99999px;
}

.scroll-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollAdjust::-webkit-scrollbar {
  width: 0px;
}

.clip-bottom {
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
}

/* Arrows for input type number : in mobile view */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1 !important;
  margin: 0;
  width: 15px;
  height: 35px;
}